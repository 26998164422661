import { computed } from 'vue';
import { useStore } from 'vuex';

const _isObject = (value) => typeof value === 'object' && !Array.isArray(value);

const _getStatesByObject = (store, mod, states, initialStates = {}) => {
  return Object
    .keys(states)
    .reduce((accumulator, state) => {
      return { 
        ...accumulator,
        [state]: computed(() => store.state[mod][states[state]])
      };
    }, initialStates);
};

const _getGettersByObject = (store, mod, getters, initialGetters = {}) => {
  return Object
    .keys(getters)
    .reduce((accumulator, getter)=>{
      return { 
        ...accumulator, 
        [getter]: computed(() => store.getters[`${mod}/${getters[getter]}`])
      };
    }, initialGetters);
};

const _getMutationsByObject = (store, mod, mutations, initialMutations = {}) => {
  return Object
    .keys(mutations)
    .reduce((accumulator, mutation)=>{
      return { 
        ...accumulator, 
        [mutation]: (value) => store.commit(`${mod}/${mutations[mutation]}`, value)
      };
    }, initialMutations);
};

const _getActionsByObject = (store, mod, actions, initialActions = {}) => {
  return Object
    .keys(actions)
    .reduce((accumulator, action)=>{
      return { 
        ...accumulator, 
        [action]: (value) => store.dispatch(`${mod}/${actions[action]}`, value)
      };
    }, initialActions);
};

export const mapAllStates = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store.state).map(key => [key, computed(() => store.state[key])])
  );
};

export const mapAllGetters = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store.getters).map(getter => [
      getter,
      computed(() => store.getters[getter])
    ])
  );
};

export const mapAllMutations = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store._mutations).map(mutation => [
      mutation,
      value => store.commit(mutation, value)
    ])
  );
};

export const mapAllActions = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store._actions).map(action => {
      return [action, value => store.dispatch(action, value)];
    })
  );
};

export const mapState = (mod, state) => {
  const store = useStore();
  if(!state) return computed(() => store.state[mod]);
  return computed(() => store.state[mod][state]);
};

export const mapGetter = getter => {
  const store = useStore();
  return computed(() => store.getters[getter]);
};

export const mapGetterFn = getter => {
  const store = useStore();
  return store.getters[getter];
}

export const mapMutation = (mutation, value) => {
  const store = useStore();
  return store.commit(mutation, value);
};

export const mapAction = (mod, action) => {
  const store = useStore();
  return (value) => store.dispatch(`${mod}/${action}`, value);
};

/**
 * 
 * @param {String} mod Vuex module in case of namespacing 
 * @param {Object} states All the states of the module that you want computed
 * @returns {Object} Returns the computed states
 * @example const { isServer } = mapStates('page', ['isServer']);
 * @example const { isSSR, internals } = mapStates('page', [{ isSSR: 'isServer' }, 'internals']);
 * @example const { isSSR } = mapState('page', { isSSR: 'isServer', internals: 'getInternals' });
 */
export const mapStates = (mod, states) => {
  const store = useStore();
  if(!states) return store.state[mod];
  if(_isObject(states)) return _getStatesByObject(store, mod, states);
  return states.reduce((accumulator, state) => {
    if(_isObject(state)) {
      return _getStatesByObject(store, mod, state, { ...accumulator });
    }
    return { 
      ...accumulator,
      [state]: computed( () => store.state[mod][state])
    };
  }, {});
};

/**
 * 
 * @param {String} mod Vuex module in case of namespacing 
 * @param {Object} getters All the getters of the module that you want computed
 * @returns {Object} Returns the computed getters
 * @example const { getInternals } = mapGetters('page', ['getInternals']);
 * @example const { internals, isServer } = mapGetters('page', [ { internals: 'getInternals' }, 'isServer' ]);
 * @example const { internals } = mapGetters('page', { internals: 'getInternals' });
 */
export const mapGetters = (mod, getters) => {
  const store = useStore();
  if(_isObject(getters)) return _getGettersByObject(store, mod, getters)
  return getters.reduce((accumulator, getter) => {
    if(_isObject(getter)) {
      return _getGettersByObject(store, mod, getter, { ...accumulator });
    }
    return { 
      ...accumulator, 
      [getter]:  computed( () => store.getters[`${mod}/${getter}`]) 
    };
  }, {});
};


/**
 * 
 * @param {String} mod Vuex module in case of namespacing 
 * @param {Object} mutations All the mutations of the module that you want 
 * @returns {Object} Returns the mutations
 * @example const { initPage } = mapGetters('page', ['initPage']);
 * @example const { initializePage } = mapGetters('page', [ { initializePage: 'initPage' } ]);
 * @example const { initializePage } = mapGetters('page', { initializePage: 'initPage' });
 */
export const mapMutations = (mod, mutations) => {
  const store = useStore();
  if(_isObject(mutations)) return _getMutationsByObject(store, mod, mutations);
  return mutations.reduce((accumulator, mutation) => {
    if(_isObject(mutation)){
      return _getMutationsByObject(store, mod, mutation, { ...accumulator });
    }
    return { 
      ...accumulator,
      [mutation]: (value) => store.commit(`${mod}/${mutation}`, value)
    };
  }, {});
};

/**
 * 
 * @param {String} mod Vuex module in case of namespacing 
 * @param {Object} mutations All the mutations of the module that you want 
 * @returns {Object} Returns the mutations
 * @example const { initPage } = mapActions('page', ['initPage']);
 * @example const { initializePage, getStock } = mapActions('page', [{ initializePage: 'initPage' }. 'getStock']);
 * @example const { initializePage } = mapActions('page', { initializePage: 'initPage' });
 */
export const mapActions = (mod, actions) => {
  const store = useStore();
  if(_isObject(actions)) return _getActionsByObject(store, mod, actions);
  return actions.reduce((accumulator, action) => {
    if(_isObject(action)) {
      return _getActionsByObject(store, mod, action, { ...accumulator })
    }
    return { 
      ...accumulator,
      [action]: (value) => store.dispatch(`${mod}/${action}`, value)
    };
  }, {});
};